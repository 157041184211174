<template>
  <div class="dashboard">
    <section class="hero">
      <div class="hero-body" style="padding-top: 0; padding-left: 0; padding-bottom: 30px;">
        <h1 class="title">
          Settings
        </h1>
      </div>
    </section>
    <form @submit.prevent="handleSubmit">
      <b-field label="Invite a member into your workspace" expanded>
      </b-field>
      <b-field grouped>
        <b-autocomplete
          expanded
          v-model="email"
          placeholder="e.g. janesmith@gmail.com"
          :data="filteredAllUsersEmailExceptCurrentUser"
          @select="option => selected = option">
        </b-autocomplete>
        <p class="control">
          <b-button
            class="button is-primary"
            native-type="submit"
            icon-right="user-plus"
            :loading="submitBtnLoading">
            Send Invite
          </b-button>
        </p>
      </b-field>
    </form>
    <br />
      <!-- <button class="button field is-danger" @click="checkedRows = []"
        v-if="isOwnerOfActiveWorkspace"
        :disabled="!checkedRows.length">
        <b-icon icon="times"></b-icon>
        <span>Remove User</span>
      </button> -->
    <b-table
      :hoverable="true"
      :striped="true"
      :data="modifiedUsers"
      :checked-rows.sync="checkedRows"
      :is-row-checkable="(row) => !row.isOwner"
      :checkable="isOwnerOfActiveWorkspace"
      :checkbox-position="checkboxPosition">

      <b-table-column field="index" label="ID" v-slot="props">
          {{ props.row.index }}
      </b-table-column>

      <b-table-column field="fullName" label="Full Name" v-slot="props">
          {{ props.row.fullName }}
      </b-table-column>

      <b-table-column field="email" label="Email" v-slot="props">
          {{ props.row.email }}
      </b-table-column>
      <b-table-column field="isOwner" label="Membership" v-slot="props">
          {{ props.row.isOwner ? 'Owner' : 'Member' }}
      </b-table-column>

      <template slot="bottom-left" v-if="isOwnerOfActiveWorkspace">
        <b>Total checked</b>: {{ checkedRows.length }}
      </template>
    </b-table>
    <!-- <hr>
    <b-field
      label="Full Name"
      label-for="fullName">
      <b-input
        :value="fullName"
        placeholder="Must contain letters only"
        id="fullName"
        name="fullName"
        required>
      </b-input>
    </b-field>
    <div class="has-text-centered">
      <b-button
        class="button is-primary"
        native-type="submit"
        @click="handleSaveChanges">
      Save Changes
      </b-button>
    </div> -->
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as INBOX_CONSTANTS } from '@/store/modules/inbox/inbox.constants';

const clone = require('rfdc')({ proto: false });

export default {
  name: 'SettingsView',
  data() {
    return {
      checkboxPosition: 'left',
      checkedRows: [],
      email: '',
      selected: null,
      submitBtnLoading: false,
      fullName: this.$store.getters[USER_CONSTANTS.GETTERS.CURRENT_USER].fullName,
    };
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.CURRENT_USER,
      USER_CONSTANTS.GETTERS.USERS,
      USER_CONSTANTS.GETTERS.ALL_USERS_PROFILE_EXCEPT_CURRENT_USER,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
    ]),
    modifiedUsers() {
      const copy = clone(this[USER_CONSTANTS.GETTERS.USERS]);

      return copy.filter((user, index) => {
        if (this.ACTIVE_WORKSPACE.members.includes(user._id)) {
          // eslint-disable-next-line no-param-reassign
          user.isMember = true;
          // eslint-disable-next-line no-param-reassign
          user.index = index;
        }

        if (user._id === this.ACTIVE_WORKSPACE.owner) {
          // eslint-disable-next-line no-param-reassign
          user.isOwner = true;
        }

        return user.isMember;
      });
    },
    isOwnerOfActiveWorkspace() {
      return this.CURRENT_USER._id === this.ACTIVE_WORKSPACE.owner;
    },
    allUsersEmailExceptCurrentUser() {
      return this.ALL_USERS_PROFILE_EXCEPT_CURRENT_USER.map(user => user.email);
    },
    filteredAllUsersEmailExceptCurrentUser() {
      return this.allUsersEmailExceptCurrentUser.filter(option => option
        .toString()
        .toLowerCase()
        .indexOf(this.email.toLowerCase()) >= 0);
    },
  },
  methods: {
    handleSaveChanges() {

    },
    async handleSubmit() {
      this.submitBtnLoading = true;
      const { selected } = this;
      if (selected) {
        const to = selected;
        const { email: from } = this.CURRENT_USER;

        this.$store.dispatch(INBOX_CONSTANTS.ACTIONS.INBOX_CREATE, { from, to })
          .then(() => {
            this.success(to);
          })
          .catch((error) => {
            const errorMessage = get(error, 'response.data.error');
            if (errorMessage) {
              this.warning(errorMessage);
            } else {
              this.fail(to);
            }
          });
      } else {
        this.$buefy.toast.open({
          message: 'Please select an email from the dropdown list.',
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
      }
      this.submitBtnLoading = false;
    },
    success(to) {
      this.$buefy.notification.open({
        message: `Success! And invite was send to ${to}`,
        duration: 5000,
        type: 'is-success'
      });
    },
    warning(errorMessage) {
      this.$buefy.notification.open({
        message: `Warning! ${errorMessage}`,
        duration: 5000,
        type: 'is-warning'
      });
    },
    fail(to) {
      this.$buefy.notification.open({
        message: `Error! Failed to send an invite to ${to}`,
        duration: 5000,
        type: 'is-danger'
      });
    }
  },
  created() {
    this.$store.dispatch(USER_CONSTANTS.ACTIONS.USERS_REQUEST)
      .catch(error => console.error(error));
  }
};
</script>
